'use client'; // Error components must be Client Components

import Image from 'next/image';
import { useEffect } from 'react';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return (
    <main>
      <section className='bg-white'>
        <div className='layout flex min-h-screen flex-col items-center justify-center text-center text-black'>
          <Image
            src='/svg/Icon.svg'
            alt='N'
            width='128'
            height='128'
            style={{ height: 'auto' }}
          />
          <h1 className='mt-8 text-4xl md:text-6xl'>
            Oops, something went wrong!
          </h1>
          <a
            href='#'
            onClick={reset}
            className='my-8 text-xl text-gold-600 hover:underline'
          >
            Try again
          </a>
        </div>
      </section>
    </main>
  );
}
